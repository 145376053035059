<script setup lang="ts">
import { amountFilter } from '@/utils/common'
import useUserStore from '@/store/user'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import useAppStore from '@/store/app'
import useBannerStore from '@/store/banner'
import { LANGUAGES } from '@/store/app/type'
import { computed, onMounted } from 'vue'
import { showToast } from 'vant'
import { deviceInfo, nextRoute as nextRouteToNative } from '@/utils/native'
import useCustomerStore from '@/store/customer'
import { useChatWoot } from '@productdevbook/chatwoot/vue'
import { includes } from 'lodash-es'
import Banner from '@/components/login/banner.vue'

const env = import.meta.env.VITE_APP_ENV

const router = useRouter()
const route = useRoute()
const { t } = useI18n()

const login = () => {
  if (deviceInfo.isNativePro) {
    // 发送事件通知原生跳转登录页面
    nextRouteToNative({ route: 'login' })
  } else {
    router.push('/login')
  }
}

const userStore = useUserStore()
const appStore = useAppStore()
const bannerStore = useBannerStore()
const customerStore = useCustomerStore()
let customerTimer: string | number | NodeJS.Timeout | undefined = undefined
const props = defineProps({
  title: {
    type: String,
    required: false
  }
})

const getBalance = () => {
  if (userStore.userInfo.token) {
    userStore.getBalance()
  }
}

const selfRoute = computed(() => {
  const routeName = router.currentRoute.value.name
  const names = ['depositInformation']
  // @ts-ignore
  if (names.indexOf(routeName) > -1) {
    return true
  }
  return false
})

onMounted(() => {
  bannerStore.getBannerList()
  getBalance()
})

const switchLanguage = () => {
  // 生产环境不允许切换语言
  if (isProduction.value) {
    return
  }

  if (appStore.lang === LANGUAGES.VN) {
    appStore.changeLang(LANGUAGES.CMN)
    showToast('Switch to Chinese successfully')
  } else {
    appStore.changeLang(LANGUAGES.VN)
    showToast('Chuyển sang tiếng Việt thành công')
  }
  window.location.reload()
}

const isProduction = computed(() => env === 'production')

const toWalletPage = () => {
  if (deviceInfo.isNativePro) {
    // 发送事件通知原生跳转钱包页面
    nextRouteToNative({ route: 'wallet' })
  } else {
    router.push('/wallet/deposit/form')
  }
}
const handleService = async () => {
  await customerStore.goToCustomerService()
  customerTimer = setTimeout(() => {
    const { popoutChatWindow } = useChatWoot()
    const element = document.getElementsByClassName('woot-widget-holder').item(0)
    const elementClassName = element?.className
    const isHidding = includes(elementClassName, 'woot--hide')
    // 如果客服是隐藏状态，则打开新窗口
    if (isHidding) {
      popoutChatWindow()
    }
  }, 400)
}
</script>
<template>
  <div v-if="['login', 'register'].includes(route.name as string)" class="banner-title">
    <Banner />
  </div>
  <div v-else class="banner-title">
    <div v-if="props.title" class="title">
      {{ props.title }}
    </div>
    <div v-else class="title">
      <img class="logo" src="@/assets/components/bannerLogo.png" alt="" @click="switchLanguage" />
    </div>
    <template v-if="userStore?.userInfo?.id">
      <div class="user-name">
        <div class="user-info">
          <div class="name-text">
            <div class="vip-level">V{{ userStore?.userInfo?.vipLevel }}</div>
            {{ userStore?.userInfo?.userName }}
          </div>
          <div class="money-num" @click="getBalance">
            {{ amountFilter(userStore.balance) }} VND
            <inline-svg color="white" class="balance-refresh" :class="{ 'balance-refreshing': userStore.loadingBalance }" src="src/assets/components/icon_refresh_white.svg" />
          </div>
        </div>
      </div>
      <!-- 默认跳转到充值页面 -->
      <div class="wallet" @click="toWalletPage">
        <div>
          <img src="@/assets/components/Group-w.svg" alt="" />
        </div>
        <div class="wallet-title">
          <span>Ví</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="header-right">
        <van-button class="btn login-btn" @click="router.push('/register')">Đăng ký</van-button>
        <van-button class="btn register-btn" @click="router.push('/login')">Đăng nhập</van-button>
      </div>
    </template>

    <div class="customer-area">
      <div class="customer" @click="handleService">
        <img src="@/assets/components/small_icon.svg" alt="" />
      </div>
      <span>Hỗ trợ</span>
    </div>
  </div>
</template>
<style scoped lang="scss">
.logo {
  vertical-align: middle;
  box-sizing: border-box;
  width: 68px;
}
.header-right {
  display: flex;
  align-items: center;
  margin-left: auto;
  .btn {
    height: 32px;
    padding: 12px;
    border-radius: 48px;
    font-size: 14px;
    border: none;
  }

  .register-btn {
    background: #dfbf83;
    margin-left: 4px;
  }
}

.customer-area {
  margin-left: 12px;
  color: #fff;
  text-align: center;

  .customer {
    width: 28px;
    height: 28px;
    background: linear-gradient(327deg, #e9c98c 7.42%, #fdf3e1 95.06%);
    border-radius: 8px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 20px;
      height: 20px;
    }
  }
  span {
    line-height: 12px;
    font-size: 8px;
    font-weight: 700;
  }
}

.banner-title {
  width: 100%;
  height: 58px;
  text-align: left;
  display: flex;
  position: relative;
  padding: 8px 0px;
  color: #fff;
  background-color: #1e1e1e;
  position: sticky;
  top: 0px;
  z-index: 3;
  padding: 8px 8px;
  .title {
    font-size: 24px;
    font-weight: 600;
    height: 50px;
    line-height: 50px;
  }

  .user-name {
    display: flex;
    align-items: center;
    margin-left: auto;
    // background-image: url('@/assets/settings/text_login.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: right top;

    .user-info {
      position: relative;
      text-align: right;
    }

    .name-text {
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      display: inline-flex;
      margin-top: 5px;

      .vip-level {
        margin-right: 5px;
        width: 24px;
        height: 12px;
        border-radius: 5px;
        box-shadow: 0px 2px 2px 0px #00000040;
        background: #fbe6be;
        color: #000000;
        font-family: Inter;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 4px;
      }
    }
    .money-num {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }

    .balance-refresh {
      width: 10px;
      height: 10px;
    }
    .balance-refreshing {
      animation: rotate 0.5s infinite linear;
    }
  }

  .wallet {
    position: relative;
    width: 28px;
    margin-left: 20px;
    > div:first-child {
      width: 80px;
      pointer-events: none;
    }
    img {
      width: 100px;
      position: relative;
      top: -13px;
      left: -36px;
      pointer-events: none;
    }

    .wallet-title {
      position: absolute;
      bottom: -3px;
      left: 30%;
      translate: transformX(-50%);
      z-index: 9;
      //font-family: Poppins;
      font-size: 8px;
      font-weight: 700;
      line-height: 12px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
}

.banner-contianer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  .login_in {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, rgba(255, 248, 235, 0) 3.94%, rgba(255, 255, 255, 0.49) 36.07%);
    border-radius: 0px 10px 10px 0px;
    height: 100%;

    .text {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: right;
      width: 237px;
    }

    .img-wrap {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
