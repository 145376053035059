import router from '@/router'
import { showToast } from 'vant'
import { isString } from 'lodash-es'
import useAppStore from '@/store/app'
import useUserStore from '@/store/user'
import { i18n } from '@/locale'
import { UserInfo } from '@/store/user/types'
import emitter, { Events } from '@/utils/eventBus'
import { cache } from '@hpoker/sdk'

const { localCache } = cache

const {
  global: { t }
} = i18n

interface ISendParams {
  method: string
  params?: object
  id?: string
}
interface IOnSendParams {
  cbid: string
  data: {
    params: object
    method: string
  }
}

interface IGetAgentCode {
  agent: string
}
interface IResponse {
  cbid: string
  data?: { [property: string]: any }
  errorCode?: number
  errorMessage?: string
}

export enum ID_KEYS {
  GET_USERINFO = 'getUserInfo',
  SET_USERINFO = 'setUserInfo',
  DISMISS = 'dismiss',
  CONFIG_CONTAINER = 'configContainer',
  OPEN_WEBVIEW = 'openWebView',
  GET_DEVICE_FINGER = 'getDeviceFinger',
  GET_EXTRA_LAYOUT = 'getExtraLayout',
  COPY_TEXT = 'copyText',
  TOKEN_EXPIRED = 'tokenExpired',
  PROMO_CALLBACK = 'promoCallback',
  CHECK_AGENT = 'checkAgent',
  ROUTE_CHANGE = 'routeChange',
  NEXT_ROUTE = 'nextRoute'
}

export enum NATIVE_METHODS {
  GET_USERINFO = 'getUserInfo', // 获取原生提供的用户信息
  STORE_USERINFO = 'storeUserInfo', // 保存用户信息
  STORE_VALUE_FOR_KEY = 'storeValueForKey', // 通过key value方式保存值
  GET_STORAGE_VALUE_FOR_KEY = 'getStorageValueForKey', // 返回保存的某个Key的值
  OPEN_BROWSER = 'openBrowser', // 跳转到外部浏览器打开链接
  OPEN_WEBVIEW_PAGE_SHEET = 'openWebViewPageSheet', // 新打开一个 web 容器
  DISMISS = 'dismiss', // 退出app
  CONFIG_CONTAINER = 'configContainer', // 配置 Web容器, 如标题，返回键，关闭按钮 等
  GET_APP_VERSION = 'getAppVersion', // 获取App版本号
  GET_DEVICE_FINGER = 'getDeviceFinger', // 从原生获取设备指纹
  GET_EXTRA_LAYOUT = 'getExtraLayout', // 获取状态栏和底部安全区域高度
  COPY_TEXT = 'copyText', // 复制文本到剪贴板
  TOKEN_EXPIRED = 'tokenExpired', // token生效 需要重新登陆. 通知Native重新登陆 然后返回首页（Native toast展示message信息）
  PROMO_CALLBACK = 'promoCallback', // 活动详情返回回调
  CHECK_AGENT = 'checkAgent', // APP检查当前粘贴板,获取代理code
  ROUTE_CHANGE = 'routeChange', // 通知原生路由变化
  NEXT_ROUTE = 'nextRoute' // 通知原生跳转相应原生页面
}

function getDevice() {
  const isDefaultH5 = true

  const deviceInfo = {
    name: '',
    type: isDefaultH5 ? 1 : -1, // 设备类型 PC:0 H5:1 ANDROID:2 IOS:3 PAD:4
    isBrowser: false,
    isNative: false,
    isNativePro: false, // 是否在新版原生版本中
    isIos: false,
    isAndroid: false
  }

  const urlParams = new URLSearchParams(window.location.search)
  if (urlParams.get('inNative') === '1') {
    localCache.setCache('isNativePro', true)
  }
  const isNativePro = localCache.getCache('isNativePro')

  try {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.send) {
      return Object.assign({}, deviceInfo, {
        name: 'IOS',
        type: isDefaultH5 ? 1 : 3,
        isIos: true,
        isNative: true,
        isNativePro
      })
    } else if (window.WebViewJavascriptBridge) {
      return Object.assign({}, deviceInfo, {
        name: 'Android',
        type: isDefaultH5 ? 1 : 2,
        isAndroid: true,
        isNative: true,
        isNativePro
      })
    }
  } catch (e) {}

  return Object.assign({}, deviceInfo, {
    name: 'H5',
    type: 1,
    isBrowser: true,
    isNative: false,
    isNativePro: false
  })
}

export const deviceInfo = getDevice()

// 向native发送消息
export function sendToNative({ method, params = {}, id }: ISendParams) {
  try {
    const data = {
      data: {
        method,
        params
      },
      cbid: id
    }
    if (window.webkit) {
      window.webkit.messageHandlers.send.postMessage(data)
    } else {
      window.WebViewJavascriptBridge.send(JSON.stringify(data))
    }
  } catch (e) {}
}

export function responseToNative({ cbid, data = {}, errorCode = 0, errorMessage = '' }: IResponse) {
  try {
    const params = { cbid, data, errorCode, errorMessage }
    if (window.webkit) {
      window.webkit.messageHandlers.response.postMessage(params)
    } else {
      window.WebViewJavascriptBridge.response(JSON.stringify(params))
    }
  } catch (e) {}
}

function onGetDeviceFingerSuccess(finger: string) {
  const appStore = useAppStore()
  if (finger) {
    appStore.setDeviceId(finger)
  } else {
    appStore.getDeviceFingerPrint()
  }
}

function onGetDeviceFingerError() {
  const appStore = useAppStore()
  appStore.getDeviceFingerPrint()
}

function onGetUserInfoSuccess(data: UserInfo) {
  const userStore = useUserStore()
  if (data && data.token) {
    userStore.setToken(data.token)
    userStore.setUserInfo(data, true)
  } else {
    // 清除浏览器缓存的用户信息，防止原生app登出后，内嵌的H5浏览器端还保留用户信息
    userStore.clearInfo()
  }
  emitter.emit(Events.GET_NATIVE_USERINFO_SUCCESS, data && data.token ? data : null)
}

function onGetUserInfoError() {
  const userStore = useUserStore()
  userStore.clearInfo()
  emitter.emit(Events.GET_NATIVE_USERINFO_ERROR)
}

function onGetAgentCodeSuccess(data: IGetAgentCode) {
  const userStore = useUserStore()
  if (data) {
    const code = data.agent
    // 如果粘贴内容是代理code格式，则保存到store
    if (/^vip\d{1,6}$/.test(code)) {
      userStore.setAgentCode(data.agent)
    }
  }
}

// 接收native的response
// @ts-ignore
window.response = function (jsonData: IResponse | string = '') {
  if (isString(jsonData)) {
    jsonData = JSON.parse(jsonData)
  }
  const { cbid, data = {}, errorCode = 0 } = jsonData as IResponse

  // 成功
  if (errorCode === 0) {
    console.log(`接收native response成功， cbid: ${cbid}, data: ${JSON.stringify(data)}`)
    switch (cbid) {
      // 接收原生传递过来的设备id
      case ID_KEYS.GET_DEVICE_FINGER:
        onGetDeviceFingerSuccess(data.finger)
        break
      // 接收原生传递过来的用户信息
      case ID_KEYS.GET_USERINFO:
        onGetUserInfoSuccess(data as UserInfo)
        break
      case ID_KEYS.GET_EXTRA_LAYOUT:
        onGetExtraLayoutSuccess(data as IExtraLayout)
        break
      // 接受原生传递过来的代理code
      case ID_KEYS.CHECK_AGENT:
        onGetAgentCodeSuccess(data as IGetAgentCode)
        break
      default:
        break
    }
    return
  }

  // 失败
  switch (cbid) {
    // 接收原生传递过来的设备id
    case ID_KEYS.GET_DEVICE_FINGER:
      onGetDeviceFingerError()
      break
    // 接收原生传递过来的用户信息
    case ID_KEYS.GET_USERINFO:
      onGetUserInfoError()
      break
    default:
      break
  }
}

// 暴露给native的方法
// @ts-ignore
window.send = function (jsonData: IOnSendParams | string = '') {
  if (isString(jsonData)) {
    jsonData = JSON.parse(jsonData)
  }
  const {
    cbid,
    data: { method, params }
  } = jsonData as IOnSendParams

  switch (method) {
    case 'goBack':
      onGoBack(cbid, params)
      break
  }
}

// Native导航栏点击返回按钮 或 Android 物理返回键触发
let first: number | null = null
function onGoBack(cbid: string, params: object) {
  const tabPaths = ['/', '/sports', '/promotion', '/betting', '/profile']
  try {
    const { currentRoute } = router
    // 是否在首页tab中点击
    if (deviceInfo.isAndroid && tabPaths.includes(currentRoute.value.path)) {
      if (!first) {
        first = new Date().getTime()
        showToast(t('global.exitAppToast'))
        window.setTimeout(function () {
          first = null
        }, 1000)
      } else {
        if (new Date().getTime() - first < 1000) {
          dismiss()
        }
      }
      return
    }

    // 是否可返回上一个路由
    if (window.history.state.back) {
      router.go(-1)
      responseToNative({
        cbid
      })
    } else {
      dismiss()
    }
  } catch (e) {}
}

export function dismiss() {
  // 通知APP端关闭
  sendToNative({
    method: NATIVE_METHODS.DISMISS,
    id: ID_KEYS.DISMISS
  })
}

export function checkAgentCodeFromNative() {
  sendToNative({
    method: NATIVE_METHODS.CHECK_AGENT,
    id: ID_KEYS.CHECK_AGENT
  })
}

export function routeChange(params: { before: string; next: string }) {
  console.log('routeChange', params)
  sendToNative({
    method: NATIVE_METHODS.ROUTE_CHANGE,
    id: ID_KEYS.ROUTE_CHANGE,
    params
  })
}

export function nextRoute(params: { route: string }) {
  console.log('nextRoute', params)
  sendToNative({
    method: NATIVE_METHODS.NEXT_ROUTE,
    id: ID_KEYS.NEXT_ROUTE,
    params
  })
}

// 获取原生提供的状态栏和底部安全区域高度
interface IExtraLayout {
  statusBarHeight: string
  bottomSafeHeight: string
}

function onGetExtraLayoutSuccess(data: IExtraLayout) {
  const appStore = useAppStore()
  appStore.setNativeDeviceExtraLayout(+(data.statusBarHeight ?? 0), +(data.bottomSafeHeight ?? 0))
}

interface IOpenWebViewPageSheetParams {
  url?: string
  html?: string
  title?: string
  useSystemKeyboard?: boolean
  // 0: 横屏 1: 竖屏 2: 横竖屏
  orientation?: 0 | 1 | 2
}

// 打开原生新窗口跳转三方页面
export function openWebViewPageSheet(params: IOpenWebViewPageSheetParams = {}) {
  const defaultConfigs = {
    showBack: true,
    showClose: true,
    statusBarColorStyle: 2,
    navigationBarColor: {
      begin: '#27272a',
      end: '#38383d',
      tintColor: '#FFFFFF'
    },
    orientation: 2
  }
  sendToNative({
    method: NATIVE_METHODS.OPEN_WEBVIEW_PAGE_SHEET,
    params: { configs: { ...defaultConfigs, title: params.title, useSystemKeyboard: params.useSystemKeyboard ?? true, orientation: params.orientation }, ...params },
    id: ID_KEYS.OPEN_WEBVIEW
  })
}

// 设置状态栏文字颜色
export function setStatusBarColor(routePath: string) {
  let statusBarColorStyle
  // 不带标题栏的页面
  const notInBaseLayoutPaths = ['/', '/register', '/login', '/forget', '/maintenance', '/service']
  if (notInBaseLayoutPaths.includes(routePath)) {
    statusBarColorStyle = 1
  } else {
    statusBarColorStyle = 2
  }

  if (deviceInfo.isNative) {
    sendToNative({
      method: NATIVE_METHODS.CONFIG_CONTAINER,
      params: { statusBarColorStyle },
      id: ID_KEYS.CONFIG_CONTAINER
    })
  } else {
    // @ts-ignore
    document.querySelector('meta[name="theme-color"]').setAttribute('content', statusBarColorStyle === 1 ? '#ffffff' : '#63C6FF')
  }
}

/*判断当前设备*/
export const deviceType = () => {
  let client
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    //iPhone|iPad|iPod|iOS
    client = 3
  } else if (/(Android)/i.test(navigator.userAgent)) {
    //Android
    client = 2
  } else {
    //pc
    client = 0
  }
  return client
}
// 判断是否是pc
export const isPC = () => {
  /*true则pc，false则mobile*/
  const u = navigator.userAgent
  const Agents = ['Android', 'iPhone', 'webOS', 'BlackBerry', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
  let flag = true
  for (let i = 0; i < Agents.length; i++) {
    if (u.indexOf(Agents[i]) > 0) {
      flag = false
      break
    }
  }
  return flag
}
